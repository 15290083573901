/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 0h5.293A1 1 0 0110 .293L13.707 4a1 1 0 01.293.707V14a2 2 0 01-2 2H4a2 2 0 01-2-2V2a2 2 0 012-2m5.5 1.5v2a1 1 0 001 1h2z"/>',
    },
});
